@media only screen and (max-width: 980px) {
  .game-pane .swiper {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    padding-left: 16px;
    padding-right: 16px;
  }

  /* Grid in Swiper demands fixed height, here it's implemented only for Mobile */
  .game-pane .swiper-grid {
    height: 388px;
  }

  .game-pane .swiper-grid .swiper-slide {
    width: 140px !important;
  }
}

.game-title-dark .game-card-title {
  color: black !important;
}

.game-title-dark .game-card-provider {
  color: black !important;
}
